@import '../../Styles/settings';

.root {
  padding-top: $mobile-header-height;
}
.wrapper {
  padding: $verticleBlockPaddingXS 0;
  margin: auto;
  max-width: 770px;
}

.bottomLink {
  margin-block-start: 1em;
}

.title {
  font-weight: bold;
}

.logo {
  width: 150px;
  margin-bottom: 30px;
}


@media #{$md-up} {
  .root {
    padding-top: $standard-header-height-md-up;
  }
  .wrapper {
    padding: $verticleBlockPaddingMD 0px;
  }
}
